import { useEffect, useState } from "react";
import useService from '../useService';
import './update.css';
import Select from 'react-select';
import moment from 'moment';
import './importxls.css';
import * as XLSX from 'xlsx';

export default function Importxls(props) {
    const [report, setReport] = useState({});
    const [tableData, setTableData] = useState({ table: '', orig: '', fk: '' });
    const [controlFlag, setControlFlag] = useState(false);
    const [tableKey, setTableKey] = useState([]);
    const service = useService();
    var tableName;

    function importData() {
        console.log('hell')
        document.querySelector('input').click();
    }

    async function handle(e) {
        setTableData(prv => ({ ...prv, ['table']: [] }));
        setTableKey([]);
        setReport({});

        var res = {};
        console.log('reading input file:');
        const file = e.target.files[0];
        tableName = file.name.split('-')[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'binary', cellText: false, cellDates: true });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 0,
            defval: "",
            raw: false,
            dateNF: "mm/dd/yyyy"
        });

        //to be commented when prod
        // var dd = jsonData.map(k => {
        //     for (var kk in k) {
        //         if (kk == 'age') { k['age'] = moment(k['age'], 'MM/DD/YY').format('DD/MM/YYYY') }
        //         if (kk === 'id') delete k['id'];
        //     };
        //     return k;
        // });
        
        res[tableName] = jsonData;
        //res[tableName] = createDataToSave(tableName,jsonData);
        e.target.value='';
        service.postBulkAssetData(res)
            .then(async (data) => {
                if ((data.data).length > 0) {
                    data.data.map(k=>{  if (k['age']!=null){
                        k['age']=moment(k['age']).format('YYYY-MM-DD');
                    }  
                })
                    setControlFlag(true);
                    setTableData(prv => ({ ...prv, ['table']: data.data }));
                    setTableKey(Object.keys(data.data[0]));
                }
                var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
                setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
            })
            .catch((err) => {
                if (err?.response != null)
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
                else
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
            });
    }

    return (
        <div className='import-main'>
            <div className='import-input'>
                <input className="import" type="file" onChange={(evt) => handle(evt)} />
            </div>

            {tableData['table'].length > 0 && controlFlag && (
                <div className="import-table">
                    <table>
                        <thead>
                            <tr>
                                {tableKey.map(x => (
                                    (() => {
                                        if (x != 'id') {
                                            return (
                                                <th>{x}</th>
                                            )
                                        }
                                    })()
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                        {tableData['table'].length > 0 ?
                                tableData['table'].map(k => (
                                    <tr>
                                        {tableKey.map(kk => (
                                            (() => {
                                                if (kk != 'id') {
                                                    return (
                                                        k[kk] == true ? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
                                                    )
                                                }
                                            })()
                                        ))}
                                    </tr>
                                )) : ''}
                        </tbody>
                    </table>
                </div>
            )}
            {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}
        </div>
    );
};




