import { useEffect, useState } from "react";
import axios from 'axios';
import qs from 'qs';

function useService() {
  const [count, setCount] = useState(200);
  const [posts, setPosts] = useState();
  const [jwt, setJwt] = useState('');

  const headerDict = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
  };

  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    config.headers.Authorization = jwt;// token ? `Bearer ${token}` : '';
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (typeof response.data['token'] === 'string' && response.data['token'].trim().length > 0) {
        setJwt(response.data['token']);
      }
      return response;
    },
    (error) => {
      console.error("Request failed:", error);
      return Promise.reject(error);
    }
  );

  const { REACT_APP_SERVER_URL } = process.env;

  function handleClick() {
    setCount(count - 10);
  };

  const User = {
    table: "addio"
  };

  // useEffect(()=> {
  //     fetch("http://localhost:5110/getdata")
  //         .then((data)=>setPosts(data) )
  //         .catch();
  //         window.alert(posts);
  // },[]);

  // useEffect(() => {
  // }, [posts]);

  function testApi() {
    axios.get("http://localhost:5110/getdata/ciaoget")
      .then((response) => {
        filterData(response.data);
        setPosts(response.data);
        filterData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function Auth(profile) {
    // return instance.post(`${process.env.REACT_APP_API_ADDRESS}/auth", profile);
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/auth`, profile);
  }

  function postAssetData(data) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/postassetdata`, data);
  }

  function updateAssetData(data) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/updateassetdata`, data); 
  }

  function deleteAssetData(data) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/deleteassetdata`, data);
  }

  function postData(profile) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/generatemoi`);
  }

  function postAssetDataFk(data) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/postassetdatafk`, data);
  }

  function postAssetDataFks(data) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/postassetdatafks`, data);
  }

  function getData(profile) {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/getdata`);
  }

  function getSchema() {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/getschema`);
  }

  function getAsset() {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/getasset`);
  }

  function getTableSchema() {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/gettableschema/rest_get_tableschema`);
  }

  function getAssetData(data) {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/getassetdata/` + data);
  }
  function getAssetDataPaging(data,page) {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/getassetdatapaging/` + data+'/'+page);
  }

  function getTable(profile) {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/getdata`);
  }

  function getFks(fks) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/getfks`, fks);
  }

  function postProfile(tables) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/postprofile`,tables);
  }

  function postAssetDataValue(value) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/postassetdatavalue`,value);
  }

    function exportBase(value) {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/exportbase/schema/${value}/resource/operation`);
  }

  function importBase(data) {
    return instance.get(`${process.env.REACT_APP_API_ADDRESS}/public/`+data+'/export');
  }

  function postBulkAssetData(value) {
    return instance.post(`${process.env.REACT_APP_API_ADDRESS}/postbulkassetdata`,value);
  }

  function filterData(data) {
    window.alert(posts);
    console.log(data);
  }
  return {
    Auth, postData, testApi, getData, getAsset, postAssetData, getAssetData, getSchema, getFks, getTableSchema, updateAssetData, deleteAssetData,
    postAssetDataFk,postAssetDataFks,postProfile,postAssetDataValue,exportBase,getAssetDataPaging,importBase,postBulkAssetData
  };
};

export default useService;

