import Insert from './insert';
import Update from './update';
import Delete from './delete';
import Profile from './profile';
import Profileupdate from './profileupdate';
import Profiledelete from './profiledelete';
import View from './view';
import Exportxls from './exportxls';
import Importxls from './importxls';
import Test from './test';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faCoffee, faMagic } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";

export default function Menu(props) {

  const getSelection = (action, titlename) => {
    props.sms(action);
    props.sms(prv => ({ ...prv, function: action, title: titlename }));
  };

  return (
    <div className="menu-main">
      <div className="menu-title">
        <div id='name'> </div>
        <div id='function'></div>
      </div>

      <div className="menu-body">
        <div className="menu-menu">
          <div onClick={() => getSelection('insert', 'Insert Data')} ><FontAwesomeIcon icon={faCoffee} size='lg' className="icon" /><span id='right'>Insert Data</span></div>
          <div onClick={() => getSelection('delete', 'Delete Data')} ><FontAwesomeIcon icon={faAddressBook} size='lg' className="icon" /><span id='right'>Delete Data</span></div>
          <div onClick={() => getSelection('update', 'Update Data')} ><FontAwesomeIcon icon={faAngleLeft} size='lg' className="icon" /><span id='right'>Update Data</span></div>
          <div onClick={() => getSelection('view', 'View Data')} ><FontAwesomeIcon icon={faAngleLeft} size='lg' className="icon" /><span id='right'>View Data</span></div>
          <div onClick={() => getSelection('profile', 'Profile-Skill Insert')} ><FontAwesomeIcon icon={faAngleLeft} size='lg' className="icon" /><span id='right'>Profile-Skill Insert</span></div>
          <div onClick={() => getSelection('profileupdate', 'Profileupdate Skill')} ><FontAwesomeIcon icon={faAngleLeft} size='lg' className="icon" /><span id='right'>Profile-Skill Update</span></div>
          <div onClick={() => getSelection('profiledelete', 'Profiledelete Skill')} ><FontAwesomeIcon icon={faAngleLeft} size='lg' className="icon" /><span id='right'>Profile-Skill Delete</span></div>
          <div onClick={() => getSelection('exportxls', 'Export To XMS')} ><FontAwesomeIcon icon={faAngleLeft} size='lg' className="icon" /><span id='right'>Export xls</span></div>
          <div onClick={() => getSelection('importxls', 'Import To XMS')} ><FontAwesomeIcon icon={faAngleLeft} size='lg' className="icon" /><span id='right'>Import xls</span></div>
          {/* <div onClick={() => getSelection('test', 'test')} ><FontAwesomeIcon icon={faAngleLeft} size='lg' className="icon" /><span id='right'>Test</span></div> */}
        </div>
        {/* {props.il['ishttp'] != null ? <p className={props.il['ishttp']}>  {props.il['response']}  </p> : ''} */}
        {props.ms['function'] == 'insert' ? <Insert il={props.il} sil={props.sil} schema={props.schema} ></Insert> : ''}
        {props.ms['function'] == 'update' ? <Update il={props.il} sil={props.sil} schema={props.schema} ></Update> : ''}
        {props.ms['function'] == 'delete' ? <Delete il={props.il} sil={props.sil} schema={props.schema} ></Delete> : ''}
        {props.ms['function'] == 'profile' ? <Profile il={props.il} sil={props.sil} schema={props.schema} ></Profile> : ''}
        {props.ms['function'] == 'profileupdate' ? <Profileupdate il={props.il} sil={props.sil} schema={props.schema} ></Profileupdate> : ''}
        {props.ms['function'] == 'profiledelete' ? <Profiledelete il={props.il} sil={props.sil} schema={props.schema} ></Profiledelete> : ''}
        {props.ms['function'] == 'view' ? <View il={props.il} sil={props.sil} schema={props.schema} ></View> : ''}
        {props.ms['function'] == 'test' ? <Test il={props.il} sil={props.sil} schema={props.schema} ></Test> : ''}
        {props.ms['function'] == 'exportxls' ? <Exportxls il={props.il} sil={props.sil} schema={props.schema} ></Exportxls> : ''}
        {props.ms['function'] == 'importxls' ? <Importxls il={props.il} sil={props.sil} schema={props.schema} ></Importxls> : ''}
      </div>
    </div>
  );
};