import { useEffect, useState } from "react";
import useService from '../useService';
import Profile from '../entity/profile';
import './exportxls.css';
import Select from 'react-select';
import moment from 'moment';
import { saveAs } from 'file-saver';

export default function Exportxls(props) {
    const [options, setOptions] = useState({});
    const [tableSchema, settableSchema] = useState([]);
    const service = useService();
    const [report, setReport] = useState({});
    const [table, setTable] = useState();

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            height: '38px',
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'hotpink' : 'white',
        }),
    };

    function getTableSchema() {
        var idx;
        service.getTableSchema()
            .then(async (data) => {
                var tables = Object.keys(data.data).map(k => { return { label: k, value: k }; }).filter(kk => { return kk.value != 'userprofile' });
                setOptions(prv => ({ ...prv, ['table']: tables }));
            })
            .catch((err) => {
                if (err?.response != null)
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
                else
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
            });
    }
    useEffect(() => {
        getTableSchema();
    }, []);

    function getSelection(env, data) {
        setTable(data.value);
    }

    function submit(evt) {
        setReport({});
        // window.location=`http://localhost:888/api/exportbase/mytable/${table}/table/${table}`;
        window.open(`http://localhost:888/api/exportbase/mytable/${table}/table/${table}`, '_blank');
        return null;
        // service.exportBase(table)
        //     .then(async (data) => {
        //     //     const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //     //     const file = new File([blob], 'test.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //     //     saveAs(data.data);
        //        window.location='http://localhost:888/api/exportbase/mytable/technology/name/res';

        //         var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
        //         setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
        //     })
        //     .catch((err) => {
        //         if (err?.response != null)
        //             setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        //         else
        //             setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
        //     });
    };

    return (
        <div className='export-main'>
            <div className="export-select">
                <Select options={options['table']} onChange={(e) => getSelection("asset", e)} className="select" styles={customStyles} placeholder='Select Asset' />
            </div>
            <div className="export-action">
                <button onClick={submit}>Export to xls</button>
            </div>
            {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}
        </div>
    );
};