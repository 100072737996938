import { useEffect, useState } from "react";
import useService from '../useService';
import Profile from '../entity/profile';
import './view.css';
import Select from 'react-select';
import moment from 'moment';

export default function View(props) {

    const [options, setOptions] = useState({});
    const [dataInput, setDataInput] = useState({});
    const [assetValue, setAssetValue] = useState({});
    const [tableKey, setTableKey] = useState([]);
    const [tableKeyFk, setTableKeyFk] = useState([]);
    const [tableData, setTableData] = useState({ table: '', orig: '', fk: '' });
    const [control, setControl] = useState({});
    const [fksResult, setFksResult] = useState([]);
    const [tableSchema, settableSchema] = useState([]);
    const [rowData, setRowData] = useState({});
    const [filters, setFilters] = useState({});
    const service = useService();
    const [report, setReport] = useState({});
    const [controlFlag, setControlFlag] = useState(false);
    const [isCleared, setIsCleared] = useState(false);
    const [pages, setPages] = useState([{}]);
    const [preRow, setPreRow] = useState('');

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            height: '38px',
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'hotpink' : 'white',
        }),
    };

    function getTableSchema() {
        var idx;
        service.getTableSchema()
            .then(async (data) => {
                var tables = Object.keys(data.data).map(k => { return { label: k, value: k }; }).filter(kk => { return kk.value != 'userprofile' });
                setOptions(prv => ({ ...prv, ['table']: tables }));
                Object.keys(data.data).forEach(k => {
                    data.data[k].forEach(kk => {
                        var x = kk[Object.keys(kk)[0]];
                        kk[Object.keys(kk)[0]] = x == 'timestamp' ? 'date' : x == 'integer' || x == 'double' ? 'number' : x == 'boolean' ? 'boolean' : 'text';
                    });
                });

                for (const [key, value] of Object.entries(data.data)) {
                    idx = value.findIndex(x =>
                        Object.keys(x)[0] == 'id'
                    );
                    if (idx >= 0)
                        data.data[key].splice(idx, 1);
                };
                settableSchema(data.data);
                props.sil(prv => ({ ...prv, response: 'Response: ' + data.data.message + " \n  Status: " + data.statusText, ishttp: 'ok' }));
                //  return tables
            })
            .catch((err) => {
                if (err?.response != null)
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
                else
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
            });
    }
    useEffect(() => {
        getTableSchema();
    }, []);

    function getSelection(event = null, page = 1) {
        setControlFlag(false);
        setRowData({});
        setTableData(prv => ({ ...prv, ['fk']: '' }));
        setTableData(prv => ({ ...prv, ['table']: '' }));
        setReport({});
        setControl(prv => ({ ...prv, 'visible': false }));
        dataInput['asset'] = event;
        getFks();
        service.getAssetDataPaging(event, page)
            .then(async (data) => {
                if (Object.keys(data.data.data).length == 0) {
                    return null;
                }
                var rows = JSON.parse(data.data.data);
                rows.map(row => {
                    Object.keys(row).map(rowkey => {
                        var idx = tableSchema[event].findIndex(x => Object.keys(x)[0] == rowkey);
                        if (idx >= 0 && tableSchema[event][idx][rowkey] == 'date') {
                            row[rowkey] = moment(row[rowkey]).format('YYYY-MM-DD');
                        }
                    });
                });
                setPages(data.data.pages);

                var listTmpRows = cloneTableData(rows);
                setTableData(prv => ({ ...prv, ['table']: rows }));
                setTableData(prv => ({ ...prv, ['orig']: listTmpRows }));
                setTableKey(Object.keys(rows[0]));
                getRow(null, null, false);
                var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
                setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
            })
            .catch((err) => {
                if (err?.response != null)
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
                else
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
            });
    };

    const paging = (
        <div className="view-pagination">
            <ul>
                <li> <a id="extremepage" onClick={(evt) => getPage(evt, 1)}>&#171; </a></li>
                {pages.map(x =>
                    <li> <a onClick={(evt) => getPage(evt, x.index)} style={x.isSelected ? { pointerEvents: 'none' } : { pointerEvents: 'visible' }}>{x.index}  </a></li>
                )}
                <li> <a id="extremepage" onClick={(evt) => getPage(evt, 0)}>&raquo; </a></li>
            </ul>
        </div>
    );

    async function getFks() {
        setReport({});
        var fksListTmp = {};
        var fks = [];
        setFksResult([]);
        fksResult.length = 0;
        var fksList = [];
        fks = props.schema.filter(k => { return k.table == dataInput['asset'] });

        if (fks.length > 0) {
            setControlFlag(true);
        }
    };

    function cloneTableData(tableData) {
        var rows = {};
        var listRows = [];
        tableData.map(k => {
            for (var key in k) {
                rows[key] = k[key];
            };
            listRows.push(rows);
            rows = {};
        });
        return listRows;
    }

    const getRow = (evt = null, rowdata = null, flag = true) => {
        var row = {};
        var obj = {};
        obj[dataInput['asset']] = [];

        var schema = props.schema.filter(k => { return k['table'] == dataInput['asset'] });
        if (flag && schema.length > 0) {
            for (var k in rowdata) {
                schema.forEach(kk => {
                    if (kk['foreignkey'] == k) {
                        var t = {};
                        t['key'] = k;
                        t['value'] = rowdata[k];
                        t['fktable'] = kk['foreigntable'];
                        t['keys'] = tableSchema[kk['foreigntable']].map(x => { return Object.keys(x)[0]; });
                        t['flag'] = flag;
                        obj[dataInput['asset']].push(t);
                    }
                });
            };
        }
        else if (!flag && schema.length > 0) {
            schema.forEach(kk => {
                var t = {};
                t['fktable'] = kk['foreigntable'];
                t['key'] = kk['foreignkey']
                t['keys'] = tableSchema[kk['foreigntable']].map(x => { return Object.keys(x)[0]; });
                t['flag'] = flag;
                obj[dataInput['asset']].push(t);
            });
        }
        else
            return null;

        service.postAssetDataFk(obj)
            .then(async (data) => {

                var tables = props.schema.filter(k => { return k.table == dataInput['asset'] }).map(kk => { return kk.foreigntable });
                for (var table of tables) {
                    data.data.map(row => {
                        Object.keys(row).map(rowkey => {
                            var idx = tableSchema[table].findIndex(x => Object.keys(x)[0] == rowkey.split('_')[1]);
                            if (idx >= 0 && tableSchema[table][idx][rowkey.split('_')[1]] == 'date') {
                                const formattedDate = moment(row[rowkey]);
                                var s = formattedDate.format('YYYY-MM-DD');
                                row[rowkey] = s;
                            }
                        });
                    });
                }

                if (!flag) {
                    var listTmpRows = cloneTableData(data.data);
                    setTableData(prv => ({ ...prv, ['fkorig']: listTmpRows }));
                    flag = true;
                }
                setTableData(prv => ({ ...prv, ['fk']: data.data }));
                setTableKeyFk(Object.keys(data.data[0]));
            })
            .catch((err) => {
                if (err?.response != null)
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
                else
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
            });
    }

    function getData(evt) {
        setIsCleared(false);
        var rowDataNew = {};
        var kk = evt.target.name;
        var value = evt.target.value;
        var tmp = rowData;


        if (controlFlag)
            kk = kk.split('id')[1] == '' ? kk.split('id')[0] + '_name' : '';

        if (value == 'true') {
            tmp[kk] = true;
        }
        else if (value == 'false') {
            tmp[kk] = false;
        }
        else if (value == '')
            delete (tmp[kk]);
        else if (!isNaN(value)) {
            tmp[kk] = parseInt(value);
        }
        else {
            tmp[kk] = value;
        }

        setRowData(tmp);
        for (var key in rowData) {
            rowDataNew[key] = rowData[key];
        };

        if (controlFlag) {
            var listTmpRows = cloneTableData(tableData['fkorig']);
            var res = recorsiveGetData(listTmpRows, rowDataNew);
            setTableData(prv => ({ ...prv, ['fk']: res }));

        }
        else {
            var listTmpRows = cloneTableData(tableData['orig']);
            var res = recorsiveGetData(listTmpRows, rowDataNew);
            setTableData(prv => ({ ...prv, ['table']: res }));
        }
    }

    function recorsiveGetData(data, filters) {
        if ((Object.keys(filters)).length == 0) {
            return data;
        };
        var key = Object.keys(filters)[0];
        data = filters[key] == true || filters[key] == false || !isNaN(filters[key]) ? data.filter(k => { return k[key] == (filters[key]) }) : data.filter(k => { return k[key].startsWith(filters[key]) });
        delete (filters[key]);
        var res = recorsiveGetData(data, filters);
        return res;
    }

    function getPage(evt, index) {
        // evt.currentTarget.className = 'pointeroff';
        // if (preRow != '')
        //     preRow.className = 'pointeron';
        // setPreRow(evt.currentTarget);

        getSelection(dataInput['asset'], index)
    }

    function reset(evt) {
        setIsCleared(true);
        var tmpRowData = {};
        Object.keys(rowData).forEach(k => {
            tmpRowData[k] = null;
        });
        setRowData(tmpRowData);
        setRowData({});
        setTableData(prv => ({ ...prv, ['table']: prv['orig'] }));

        if (tableData['fk'].length > 0) {
            setTableData(prv => ({ ...prv, ['fk']: prv['fkorig'] }));
        }
    }

    return (
        <div className='view-main'>
            <div className="view-select">
                <Select options={options['table']} onChange={(e) => getSelection(e.label)} className="select" styles={customStyles} placeholder='Select Asset' />
            </div>
            <div className="view-action">
                <button onClick={reset}>Clear</button>
            </div>

            <div className="view-data">
                <div>
                    {dataInput['asset'] != null ?
                        tableSchema[dataInput['asset']].map(k => (
                            <div>
                                <label className="name"> {Object.keys(k)[0]}</label>
                                {fksResult.length > 0 && fksResult.filter(kk => { return kk.key == Object.keys(k)[0] }).length > 0 ?
                                    fksResult.filter(kk => { return kk.key == Object.keys(k)[0] }).map(x => (
                                        <select onChange={(evt) => getData(evt)} name={Object.keys(k)[0]}>
                                            <option selected={!isCleared ? rowData[x.key] : ''}>{!isCleared ? rowData[x.key] : ''}</option>
                                            {options[x.key].map(xx => (
                                                <option value={xx.value}>{xx.label}</option>
                                            ))}
                                        </select>
                                    ))
                                    : k[Object.keys(k)[0]] == 'boolean' ? <select onChange={(evt) => getData(evt)} name={Object.keys(k)[0]}> <option selected={!isCleared ? rowData[Object.keys(k)[0]] : ''}>{!isCleared ? rowData[Object.keys(k)[0]] : ''}</option><option>true</option ><option>false</option> </select>
                                        : k[Object.keys(k)[0]] == 'date' ? <input name={Object.keys(k)[0]} type={k[Object.keys(k)[0]]} onChange={(evt) => getData(evt)} className='input' value={!isCleared ? rowData[Object.keys(k)[0]] : ''} />
                                            : <input name={Object.keys(k)[0]} type={k[Object.keys(k)[0]]} onChange={(evt) => getData(evt)} value={!isCleared ? rowData[Object.keys(k)[0]] : ''} />
                                }
                            </div>
                        )) : ''
                    }
                </div>
            </div>

            {tableData['table'].length > 0 && !controlFlag && (
                <div className="view-table">
                    <table>
                        <thead>
                            <tr>
                                {tableKey.map(x => (
                                    (() => {
                                        if (x != 'id') {
                                            return (
                                                <th>{x}</th>
                                            )
                                        }
                                    })()
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {tableData['table'].length > 0 ?
                                tableData['table'].map(k => (
                                    <tr onClick={(evt) => getRow(evt, k)}>
                                        {tableKey.map(kk => (
                                            (() => {
                                                if (kk != 'id') {
                                                    return (
                                                        k[kk] == true ? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
                                                    )
                                                }
                                            })()
                                        ))}
                                    </tr>
                                )) : ''}
                        </tbody>
                    </table>
                </div>
            )}

            {tableData['fk'].length > 0 && controlFlag && (
                <div className="view-table">
                    <table>
                        <thead>
                            <tr>
                                {tableKeyFk.map(x => (
                                    (() => {
                                        if (x != 'id') {
                                            return (
                                                <th>{x}</th>
                                            )
                                        }
                                    })()
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody className="tb">
                            {tableData['fk'].length > 0 ?
                                tableData['fk'].map(k => (
                                    <tr onClick={(evt) => getRow(evt, k)}>
                                        {tableKeyFk.map(kk => (
                                            (() => {
                                                if (kk != 'id') {
                                                    return (
                                                        k[kk] == true ? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
                                                    )
                                                }
                                            })()
                                        ))}
                                    </tr>
                                )) : ''}
                        </tbody>
                    </table>
                </div>
            )}

            {/* {(() => {
                if (pages != null && pages.length > 0)
                    return (
                        { paging }
                    )}
            )} */}

            {pages != null && pages.length > 1 ?
                paging
                :
                ''
            }
            {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}
        </div>
    );
};